import React, { useState } from "react";
import logo from "../assets/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userActions } from "../store/user";
import { useLocation } from "react-router-dom";
import { XLg, List } from "react-bootstrap-icons";
const Sidebar = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  if (location.pathname === "/login") {
    return;
  }

  return (
    <>
      <button
        className="toggle-sidebar"
        onClick={() => setShow((prev) => !prev)}
      >
        {show ? <XLg size={42} /> : <List size={42} />}
      </button>
      <nav className={show ? "sidebar" : "sidebar sidebar-hide"}>
        <div className="logo-container">
          <img src={logo} alt="Logo" width={"90%"} />
        </div>
        {userInfo?.data.role.find((el) => el === "Validator") && (
          <>
            <NavLink
              to={"/teams"}
              className={"sidebar-listitem"}
              onClick={() => {
                setShow(false);
              }}
            >
              Team &amp; Locations
            </NavLink>
            <NavLink
              to={"/riders"}
              className="sidebar-listitem"
              onClick={() => {
                setShow(false);
              }}
            >
              Rider
            </NavLink>
          </>
        )}
        {userInfo?.data.role.find((el) => el === "Admin") && (
          <>
            <NavLink
              className="sidebar-listitem"
              to={"/nccteams"}
              onClick={() => {
                  setShow(false);
              }}
            >
              Licenze NCC
            </NavLink>
            <NavLink
              className="sidebar-listitem"
              to={"/tags"}
              onClick={() => {
                setShow(false);
              }}
            >
              Tag
            </NavLink>
            <NavLink
              className="sidebar-listitem"
              to={"/roles"}
              onClick={() => {
                setShow(false);
              }}
            >
              Ruoli
            </NavLink>
            <NavLink
              className="sidebar-listitem"
              to={"/reviews"}
              onClick={() => {
                setShow(false);
              }}
            >
              Recensioni
            </NavLink>

            <NavLink
              className="sidebar-listitem"
              to={"/veichles-type"}
              onClick={() => {
                setShow(false);
              }}
            >
              Tipologia Veicoli
            </NavLink>


            <NavLink
              className="sidebar-listitem"
              to={"/configuration"}
              onClick={() => {
                setShow(false);
              }}
            >
              Configurazione
            </NavLink>
          </>
        )}
        {userInfo?.data.role.find((el) => el === "ExternalValidator") && (
          <NavLink
            to={"/events"}
            className="sidebar-listitem"
            onClick={() => {
              setShow(false);
            }}
          >
            Eventi, Ticket &amp; Tavoli
          </NavLink>
        )}
        {userInfo && (
          <NavLink
            to={"/subscriptions"}
            className="sidebar-listitem"
            onClick={() => {
              setShow(false);
            }}
          >
            Abbonamenti
          </NavLink>
        )}
        <NavLink
          to={"/profile"}
          className="sidebar-listitem"
          onClick={() => {
            setShow(false);
          }}
        >
          Profilo
        </NavLink>
        <NavLink
          to={"/report"}
          className="sidebar-listitem"
          onClick={() => {
            setShow(false);
          }}
        >
          Report
        </NavLink>
        <div
          className="sidebar-listitem"
          onClick={() => {
            dispatch(userActions.logout());
            setShow(false);
            navigate("/login");
          }}
        >
          Logout
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
