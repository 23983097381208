import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getActiveTeams } from "../api/dashboardAPI";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(true);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };
  useEffect(() => {
    setLoading(true);
    getActiveTeams(token, page + 1).then((res) => {
      setTeams(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
  }, [page]);

  return loading ? (
    <Loader />
  ) : (
    <>
      {" "}
      <div>
        <h1 className="title">Lista Team</h1>
        <ul>
          {teams.map((team) => {
            return (
              <li className="list-item" key={team.id}>
                <span>{team.name}</span>
                <span>
                  <Link to={"/teams/" + team.id}>
                    <button className="btn">Vedi dettagli</button>
                  </Link>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </>
  );
};

export default Teams;
