import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getVeichlesTypeList } from "../api/dashboardAPI";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";

const VeichlesType = () => {
  const { token } = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [veichlesType, setVeichlesType] = useState([]);

  useEffect(() => {
    setLoading(true);

    getVeichlesTypeList(token, page + 1).then((res) => {
      setVeichlesType(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
  }, [page, token]);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <h1 className="title">Tipologia Veicoli</h1>
      <div className="create-veichle">
        <Link to={"/create/veichles-type/"}>
          <button className="btn">Crea nuova tipologia</button>
        </Link>
      </div>
      <ul>
        {veichlesType.map((veichle) => {
          return (
            <li className="list-item" key={veichle.id}>
              <div style={{ marginRight: "auto", whiteSpace: "normal" }}>
                {veichle.title}
              </div>
              <div className="veichle-flex-info">
                <span className="veichle-seats">Posti: </span>
                <span className="list-item-button">{veichle.seats}</span>
              </div>
            </li>
          );
        })}
      </ul>
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </>
  );
};

export default VeichlesType;
