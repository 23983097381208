import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserByToken, fetchLogin } from "../api/authAPI";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const initialUserState = { loading: false, userInfo: null, token, error: null };

export const login = createAsyncThunk("user/login", (data) => fetchLogin(data));
export const getUserInfo = createAsyncThunk(
  "user/getInfo",
  (args, { getState }) => {
    const { user } = getState();
    return fetchUserByToken(user.token);
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    logout(state) {
      localStorage.removeItem("token");
      state.token = null;
      state.userInfo = null;
      state.success = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.token = action.payload.data.token;
      localStorage.setItem("token", action.payload.data.token);
      state.loading = false;
    },
    [login.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [login.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [getUserInfo.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
      state.loading = false;
    },
    [getUserInfo.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUserInfo.rejected]: (state) => {
      state.error = false;
      state.loading = false;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
