import React, { useState } from "react";
import { useEffect } from "react";
import { getMyTeams, getRiderSelf } from "../api/dashboardAPI";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Loader from "../components/Loader";
import { getActivePlan } from "../api/dashboardAPI";
const Profile = () => {
  const { token } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.user);
  const [riderSelf, setRiderSelf] = useState({});
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (userInfo)
      getRiderSelf(token)
        .then((res) => {
          setRiderSelf(res.data);
          setLoading(false);
        })
        .catch(() => {
          setRiderSelf(false);
          setLoading(false);
        });
  }, [userInfo]);

  const handleClick = (e) => {
    getMyTeams(token).then((res) => {
      getActivePlan(token, res.data[0].id).then((res) =>
        window.location.assign(res.data.url)
      );
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div>
        <div className="title">{userInfo.data.name}</div>
        <p style={{ marginBottom: "1rem" }}>{userInfo.data.email} </p>
        <p style={{ marginBottom: "1rem" }}>
          {"Città: "}
          {userInfo.data.city}
        </p>
        <p style={{ marginBottom: "1rem" }}>
          {"Dara di nascita: "}
          {dayjs(userInfo.data.dob).format("DD/MM/YYYY")}
        </p>

        <p style={{ marginBottom: "0.5rem" }}>
          Ruoli Utente: &nbsp;
          {userInfo.data.role.map((role) => (
            <span>{role}&nbsp;</span>
          ))}
        </p>

        {riderSelf ? (
          <button
            style={{ marginTop: "2rem" }}
            className="btn"
            onClick={(e) => handleClick(e)}
          >
            Gestisci
          </button>
        ) : null}
      </div>
    </>
  );
};

export default Profile;
