import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {createNCCTeam, getActivableRiders, getActiveRiders, getUsers} from "../api/dashboardAPI";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import Autocomplete from "react-google-autocomplete";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const TeamNCC = () => {
  const [activableRiders, setActivableRiders] = useState([]);
  const [activeRiders, setActiveRiders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCountActive, setPageCountActive] = useState(0);
  const [pageActive, setPageActive] = useState(0);
  const [pageCountActivable, setPageCountActivable] = useState(0);
  const [pageActivable, setPageActivable] = useState(0);
  const { token } = useSelector((state) => state.user);

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [dirty, setDirty] = useState(true);


  //form create
  const [nccTeamName, setNccTeamName] = useState("");
  const [nccTeamDescription, setNccTeamDescription] = useState("");
  const [nccTeamCompanyCountry, setNccTeamCompanyCountry] = useState("");
  const [nccTeamCompanyFiscalCode, setNccTeamCompanyFiscalCode] = useState("");
  const [nccTeamCompanyTaxCode, setNccTeamCompanyTaxCode] = useState("");
  const [nccTeamCompanyPhone, setNccTeamCompanyPhone] = useState("");
  const [nccTeamCompanyLegalAddress, setNccTeamCompanyLegalAddress] = useState("");
  const [nccTeamCompanyLegalLatitude, setNccTeamCompanyLegalLatitude] = useState("");
  const [nccTeamCompanyLegalLongitude, setNccTeamCompanyLegalLongitude] = useState("");
  const [nccTeamCompanyEmail, setNccTeamCompanyEmail] = useState("");
  const [nccTeamCompanyTax, setNccTeamCompanyTax] = useState(10.000);
  const [users, setUsers] = useState([]);
  const [nccTeamSelectedOwner, setNccTeamSelectedOwner] = useState(-1);
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect( () => {
    async function fetchUsers() {
      setLoading(true);
      let u = await getUsers(token, 1);
      let usersArray = [];
      usersArray.push(...u.data);
      let lastPage = u.meta.last_page;
      for (let i = 2; i <= lastPage; i++) {
        u = await getUsers(token, i);
        usersArray.push(...u.data);
      }
      setUsers(usersArray);
      setFirstLoad(true)
      setLoading(false);
    }
    fetchUsers()
  }, [firstLoad,dirty]);



  /*
  useEffect(() => {
    getActivableRiders(token, pageActivable + 1).then((res) => {
      setActivableRiders(res.data);
      setPageCountActivable(res.meta.last_page);
      setLoading(false);
    });
  }, [pageActivable,dirty]);

  useEffect(() => {
    getActiveRiders(token, pageActive + 1).then((res) => {
      setActiveRiders(res.data);
      setPageCountActive(res.meta.last_page);
      setLoading(false);
    });
  }, [pageActive,dirty]);
*/
  const handlePageChangeActive = (selectedObject) => {
    setPageActive(selectedObject.selected);
  };
  const handlePageChangeActivable = (selectedObject) => {
    setPageActivable(selectedObject.selected);
  };

  const handleClick = () => {
    const nccTeam = {
      name: nccTeamName,
      description: nccTeamDescription,
      company_country: nccTeamCompanyCountry,
      company_fiscal_code: nccTeamCompanyFiscalCode,
      company_tax_code: nccTeamCompanyTaxCode,
      phone: nccTeamCompanyPhone,
      address: nccTeamCompanyLegalAddress,
      latitude: nccTeamCompanyLegalLatitude,
      longitude: nccTeamCompanyLegalLongitude,
      email: nccTeamCompanyEmail,
      owner_user_id: nccTeamSelectedOwner,
      service_tax: nccTeamCompanyTax
    };
    createNCCTeam(token, nccTeam)
        .then((r) => {
          console.log("aaaa");
          setDirty(true);
          toast.success("Licenza creata", {})
        })
        .catch(() => toast.error("Si è verificato un errore", {}));
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      {
        <>
          <div className="title">Crea Licenza</div>
          <div className="form-container">
            <form className="tag-form">
              <div className="tag-name">
                <label>Nome (es: ragione sociale)</label>
                <input
                    type={"text"}
                    value={nccTeamName}
                    onChange={(e) => setNccTeamName(e.target.value)}
                ></input>
              </div>

              <div className="tag-name">
                <label>Descrizione (ad uso interno)</label>
                <input
                    type={"text"}
                    value={nccTeamDescription}
                    onChange={(e) => setNccTeamDescription(e.target.value)}
                ></input>
              </div>

              <div className="tag-name">
                <label>Stato della sede legale (es: IT)</label>
                <input
                    type={"text"}
                    value={nccTeamCompanyCountry}
                    onChange={(e) => setNccTeamCompanyCountry(e.target.value)}
                ></input>
              </div>

              <div className="tag-name">
                <label>Codice fiscale</label>
                <input
                    type={"text"}
                    value={nccTeamCompanyFiscalCode}
                    onChange={(e) => setNccTeamCompanyFiscalCode(e.target.value)}
                ></input>
              </div>
              <div className="tag-name">
                <label>Partita Iva</label>
                <input
                    type={"text"}
                    value={nccTeamCompanyTaxCode}
                    onChange={(e) => setNccTeamCompanyTaxCode(e.target.value)}
                ></input>
              </div>
              <div className="tag-name">
                <label>Iva</label>
                <input
                    type={"number"}
                    value={nccTeamCompanyTax}
                    onChange={(e) => setNccTeamCompanyTax(e.target.value)}
                ></input>
              </div>
              <div className="tag-name">
                <label>Telefono</label>
                <input
                    type={"text"}
                    value={nccTeamCompanyPhone}
                    onChange={(e) => setNccTeamCompanyPhone(e.target.value)}
                ></input>
              </div>
              <div className="tag-name">
                <label>Indirizzo sede legale</label>
                <Autocomplete
                    apiKey={GOOGLE_MAPS_API_KEY}
                    options={
                      {
                        types: ['address']
                      }
                    }
                    onPlaceSelected={(place) => {
                      setNccTeamCompanyLegalAddress(place.formatted_address);
                      setNccTeamCompanyLegalLatitude(place.geometry.location.lat().toString());
                      setNccTeamCompanyLegalLongitude(place.geometry.location.lng().toString());
                    }}
                />
              </div>
              <div className="tag-name">
                <label>Email amministrazione</label>
                <input
                    type={"email"}
                    value={nccTeamCompanyEmail}
                    onChange={(e) => setNccTeamCompanyEmail(e.target.value)}
                ></input>
              </div>

              <div className="tag-category">
                <label>Utente amministratore</label>

                <select value={nccTeamSelectedOwner}
                        onChange={(e) => setNccTeamSelectedOwner(parseInt(e.target.value))}>
                  <option value="-1">Seleziona un utente</option>
                  {users.map((user) => {
                    return (
                        <option value={user.id}>{user.email}</option>
                    );
                  })}
                </select>
              </div>

            </form>
            <button className="btn" onClick={handleClick}>
              Crea
            </button>
          </div>
        </>
      }
      <h1 className="title" style={{color: "white"}}>
        Lista Licenze
      </h1>
      {/*<ul>
        {activeRiders?.map((rider) => {
          return (
              <li className="list-item" key={rider.id}>
                {rider.user.name}

                <Link to={"/riders/" + rider.id}>
                  <button className="btn">Dettagli</button>
                </Link>
              </li>
          );
        })}
      </ul>
        <Pagination
        pageCount={pageCountActive}
      onPageChange={handlePageChangeActive}
      forcePage={pageActive}
    />*/}
    </div>
  );
};

export default TeamNCC;
