import React, { useState } from "react";
import { createVeichleType } from "../api/dashboardAPI";
import { useSelector } from "react-redux";
import {toast} from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateVeichleType = () => {
  const [veichleTypeName, setVeichleTypeName] = useState("");
  const [availableSeats, setAvailableSeats] = useState("");
  const [dirty, setDirty] = useState(true);
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleClick = () => {
    const veichleType = {
      name: veichleTypeName,
      title: veichleTypeName,
      seats: availableSeats
    };
    createVeichleType(token, veichleType)
        .then((r) => {
          setDirty(true);
          toast.success("Tipologia veicolo creata", {});
          navigate('/veichles-type')
        })
        .catch(() => toast.error("Si è verificato un errore", {}));
  };

  return (
    <div>
      {
        <>
          <div className="title">Crea Tipologia Veicolo</div>
          <div className="form-container">
            <form className="tag-form">
              <div className="tag-name">
                <label>Nome veicolo</label>
                <input
                  type={"text"}
                  value={veichleTypeName}
                  onChange={(e) => setVeichleTypeName(e.target.value)}
                ></input>
              </div>

              <div className="tag-name">
                <label>Posti disponibili</label>
                <input
                  type={"number"}
                  value={availableSeats}
                  onChange={(e) => setAvailableSeats(e.target.value)}
                ></input>
              </div>
            </form>
            <button className="btn" onClick={handleClick}>
              Crea
            </button>
          </div>
        </>
      }
    </div>
  );
};

export default CreateVeichleType;
