import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { initPayment, buyPlan } from "../api/dashboardAPI";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
  const { idTeam, idPlan } = useParams();

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    stripe
      .confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
      })
      .then((res) => {
        buyPlan(token, {
          token: res.setupIntent.payment_method,
          team_id: idTeam,
          plan: idPlan,
        })
          .then(() => navigate("/subscription/" + idTeam))
          .catch(() => setStatus("Si è verificato un errore"));
      })
      .catch((res) => {
        console.error(res);
        setStatus("Si è verificato un errore");
        setLoading(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <PaymentElement />
      <button
        type="submit"
        className="btn"
        disabled={!stripe || loading}
        style={{ mixWidth: "5rem", fontSize: "0.9rem", marginTop: "2rem" }}
      >
        {loading ? "in Elaborazione..." : "Conferma"}
      </button>
      {status && <p style={{ marginTop: "3rem" }}>{status}</p>}
    </form>
  );
};

const Checkout = () => {
  const { idTeam, idPlan } = useParams();
  const [clientSecret, setClientSecret] = useState("");
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    initPayment(token, idTeam).then((res) =>
      setClientSecret(res.data.client_secret)
    );
  }, []);
  return (
    <div className="payment-container">
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance: { theme: "night" } }}
        >
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};
export default Checkout;
