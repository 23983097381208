import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getRider,
  toggleRiderActivation,
  toggleVehicleActivation,
} from "../api/dashboardAPI";
import dayjs from "dayjs";
import Loader from "../components/Loader";

const SingleRider = () => {
  const { id } = useParams();
  const [rider, setRider] = useState();
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(true);

  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    getRider(token, id).then((res) => {
      setRider(res.data);
      setLoading(false);
      setDirty(false);
    });
  }, [dirty]);

  return loading ? (
    <Loader />
  ) : (
    <div>
      {rider && (
        <>
          <h1 className="title">{rider.user.name}</h1>
          <p>{rider.user.email}</p>
          <p style={{ marginTop: "0.5rem" }}>
            Attivo a: {rider.active_in_city}
          </p>

          {!rider.active ? (
            <button
              style={{ marginTop: "2rem" }}
              onClick={() =>
                toggleRiderActivation(token, {
                  id: rider.id,
                  active: true,
                }).then(() => setDirty(true))
              }
              className="btn"
            >
              Attiva
            </button>
          ) : (
            <p>Stato: Attivo</p>
          )}
        </>
      )}
      <h3 className="title-secondary">Veicoli</h3>
      {rider?.vehicles.map((vehicle) => {
        return (
          <div>
            <p>
              {"Modello: "}
              {vehicle.brand + " "}
              {vehicle.model}
            </p>
            <p>Targa: {vehicle.plaque}</p>
            <p>
              {"Registrato il: "}

              {dayjs(vehicle.registered_at).format("DD/MM/YYYY HH:mm")}
            </p>
            <p>Posti: {vehicle.seats}</p>

            {!vehicle.active ? (
              <button
                onClick={() =>
                  toggleVehicleActivation(token, {
                    id: vehicle.id,
                    active: true,
                  }).then(() => setDirty(true))
                }
                className="btn"
              >
                Attiva
              </button>
            ) : (
              <p>Stato: Attivo</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SingleRider;
