import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getTeam,
  getSubscriptionInfo,
  getSubscriptionPlans,
  cancelSubscription,
} from "../api/dashboardAPI";
import Loader from "../components/Loader";

const SingleSubscription = () => {
  const { id } = useParams();
  const [team, setTeam] = useState({});
  const [subscriptionInfo, setSubscriptionInfo] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(true);
  const [error, setError] = useState("");
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (dirty) {
      getTeam(token, id).then((res) => {
        setTeam(res.data);
      });
      getSubscriptionInfo(token, id)
        .then((res) => {
          setSubscriptionInfo(res.data.subscriptions);
          setLoading(false);
        })
        .catch(() => setLoading(false));
      getSubscriptionPlans(token).then((res) => {
        setSubscriptionPlans(res.data);
        setDirty(false);
      });
    }
  }, [dirty]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <h1 className="title">Abbonamento {team.name}</h1>
      {!subscriptionInfo.length == 0 ? (
        <>
          <p style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            Abbonamento attivo
          </p>
          <button
            className="btn"
            onClick={() =>
              cancelSubscription(token, id)
                .then(() => setDirty(true))
                .catch(() => setError("Si è verificato un errore"))
            }
          >
            Cancella abbonamento
          </button>
          {error && (
            <p style={{ marginTop: "2rem", color: "red", fontSize: "0.8rem" }}>
              {error}
            </p>
          )}
        </>
      ) : (
        <div>
          <p style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            Nessun abbonamento attivo
          </p>
          <div style={{ marginTop: "5rem" }}>
            <h1 className={"title"}>Abbonamenti attivabili</h1>
          </div>
          <div className="products-container">
            {subscriptionPlans.map((plan) => {
              return (
                <div className="product" key={plan.id}>
                  <div className="description">
                    <h4 style={{ fontSize: "1.2rem", fontWeight: "800" }}>
                      {plan.name}
                    </h4>
                    <h5 style={{ fontSize: "1rem" }}>{plan.price}€</h5>
                    <Link to={"/checkout/" + id + "/" + plan.id}>
                      <button className="btn">Acquista</button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SingleSubscription;
