import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEvents } from "../api/dashboardAPI";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import Loader from "../components/Loader";
const Events = () => {
  const [events, setEvents] = useState(null);
  const { token } = useSelector((state) => state.user);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    getEvents(token, page + 1).then((res) => {
      setEvents(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
  }, [page]);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div>
        <h1 className="title">Eventi</h1>
        <ul>
          {events?.length > 0 &&
            events.map((event) => {
              return (
                <li className="list-item" key={event.event.id}>
                  {event.event.name}

                  <Link to={"/events/" + event.event.id}>
                    <button className="btn">Dettagli</button>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>{" "}
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </>
  );
};

export default Events;
