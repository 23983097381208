import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createRideTierPricing,
  deleteRideTierPricing,
  editRideTier,
  editRideTierPricing,
  getRideTier,
  getRideTierPricings,
} from "../api/dashboardAPI";
import Loader from "../components/Loader";
import { Check2, X, XCircle } from "react-bootstrap-icons";
import CurrencyInput from "react-currency-input-field";
import Pagination from "../components/Pagination";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { currencyFormatter } from "../utils/currencyFormatter";
import { PencilSquare } from "react-bootstrap-icons";

const SingleTier = () => {
  const [rideTier, setRideTier] = useState({});
  const [rideTierPricings, setRideTierPricings] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedPricing, setSelectedPricing] = useState({});
  const [newKmPrice, setNewKmPrice] = useState(0);
  const [newMaxDistance, setNewMaxDistance] = useState(0);
  const [refresh, setRefresh] = useState(true);

  const { token } = useSelector((state) => state.user);
  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };

  useEffect(() => {
    Promise.all([
      getRideTier(token, id),
      getRideTierPricings(token, id, page + 1),
    ])
      .then((res) => {
        setRideTier(res[0].data);

        const sortedRideTierPricings = res[1].data.sort((a, b) => {
          if (a.max_distance === null) {
            return 1;
          }

          if (b.max_distance === null) {
            return -1;
          }

          return a.max_distance < b.max_distance ? -1 : 1;
        });

        setRideTierPricings(sortedRideTierPricings);

        setPageCount(res[1].meta.last_page);
      })
      .finally(() => {
        setLoading(false);
        setRefresh(false);
      });
  }, [token, page, id, refresh]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>{rideTier.name}</h2>
        <h3 style={{ paddingTop: "1rem" }}>{rideTier.description}</h3>
        <div style={{ paddingTop: "1rem" }}>
          Posti auto: {rideTier.max_seats}
        </div>

        <div style={{ display: "flex", gap: "30px", alignItems: "baseline" }}>
          <label>Costo di attivazione</label>
          <CurrencyInput
            type="text"
            name={rideTier.name}
            value={rideTier.activation_fee}
            onValueChange={(value) =>
              setRideTier((old) => {
                return { ...old, activation_fee: value };
              })
            }
            style={{ width: "60px", textAlign: "right" }}
            decimalsLimit={2}
            allowNegativeValue={false}
            decimalScale={2}
            suffix=" €"
          />
          <button
            className="btn"
            onClick={() =>
              editRideTier(token, {
                ...rideTier,
                activation_fee: rideTier.activation_fee.replace(
                  /[^0-9.-]+/g,
                  "."
                ),
              })
                .then(() => {
                  toast.success("Modifica salvata!", {});
                  setRefresh(true);
                })
                .catch(() => toast.error("Si è verificato un errore", {}))
            }
          >
            Salva
          </button>
        </div>

        <div style={{ padding: "2rem 0" }}>
          {rideTierPricings.map((el, index) => (
            <div key={el.id} className="list-item-tiers">
              {el.max_distance != null ? (
                <div>
                  {el.max_distance}
                  {" km"}
                </div>
              ) : (
                <div>
                  {index > 0
                    ? rideTierPricings[index - 1].max_distance + "+ "
                    : "0+ "}
                  km
                </div>
              )}
              {selectedPricing.id === el.id ? (
                <CurrencyInput
                  className="currency-input"
                  autoFocus={true}
                  type="text"
                  defaultValue={selectedPricing.km_price}
                  onValueChange={(value) => {
                    setSelectedPricing((old) => ({
                      ...old,
                      km_price: value,
                    }));
                  }}
                  style={{
                    width: "4rem",
                    height: "2rem",
                    textAlign: "right",
                    border: "none",
                    borderRadius: "5px",
                    padding: "0 10px",
                  }}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  decimalScale={2}
                  suffix=" €"
                />
              ) : (
                currencyFormatter(el.km_price)
              )}
              <div style={{ display: "flex", gap: "10px" }}>
                {selectedPricing.id === el.id ? (
                  <Check2
                    color="#f42898"
                    size={"1rem"}
                    onClick={() =>
                      editRideTierPricing(token, {
                        ...selectedPricing,
                        km_price: selectedPricing.km_price.replace(
                          /[^0-9.-]+/g,
                          "."
                        ),
                      })
                        .then(() => {
                          toast.success("Modifica salvata!", {});
                          setSelectedPricing({});
                          setRefresh(true);
                        })
                        .catch(() =>
                          toast.error("Si è verificato un errore", {})
                        )
                    }
                  />
                ) : (
                  <PencilSquare
                    size={"1rem"}
                    onClick={() => {
                      const pricing = rideTierPricings.find(
                        (e) => e.id === el.id
                      );
                      console.log(pricing);
                      setSelectedPricing(pricing);
                    }}
                  />
                )}
                {el.max_distance != null ? (
                  <>
                    <XCircle
                      size={"1rem"}
                      onClick={() =>
                        deleteRideTierPricing(token, { id: el.id })
                          .then(() => {
                            toast.success("");
                            setRefresh(true);
                          })
                          .catch(() => toast.error(""))
                      }
                    />
                  </>
                ) : (
                  <span />
                )}
              </div>
            </div>
          ))}
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
            forcePage={page}
          />
        </div>

        <div>
          <h3>Aggiungi nuova tariffa</h3>

          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "baseline",
              paddingTop: "20px",
            }}
          >
            <div>
              <input
                type="number"
                placeholder="distanza"
                style={{
                  width: "60px",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
                value={newMaxDistance}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setNewMaxDistance(e.target.value);
                  }
                }}
              />
              km
            </div>
            <div>
              <CurrencyInput
                type="text"
                value={newKmPrice}
                onValueChange={(value) => {
                  setNewKmPrice(value);
                }}
                style={{
                  width: "80px",
                  textAlign: "right",
                  padding: "0 10px",
                }}
                decimalsLimit={2}
                allowNegativeValue={false}
                decimalScale={2}
                placeholder="€"
                suffix="€"
              />
            </div>
            <button
              className="btn"
              onClick={() => {
                createRideTierPricing(token, {
                  max_distance: newMaxDistance,
                  ride_tier_id: rideTier.id,
                  km_price: newKmPrice.replace(/[^0-9.-]+/g, "."),
                })
                  .then(() => {
                    toast.success("Tariffa aggiunta con successo");
                    setRefresh(true);
                  })
                  .catch(() => toast.error("Si è verificato un errore", {}));
              }}
            >
              Aggiungi
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTier;
