import React, { useState, useEffect } from "react";
import { getMyTeams } from "../api/dashboardAPI";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";

const Subscriptions = () => {
  const [myTeams, setMyTeams] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMyTeams(token).then((res) => {
      setMyTeams(res.data);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <h1 className="title">Abbonamenti</h1>
      <ul>
        {myTeams.map((team) => {
          return (
            <li className="list-item" key={team.id}>
              <span>{team.name}</span>
              <span>
                <Link to={"/subscriptions/" + team.id}>
                  <button className="btn">Vedi dettagli</button>
                </Link>
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Subscriptions;
