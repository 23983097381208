import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ pageCount, onPageChange, forcePage }) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      pageRangeDisplayed={5}
      pageCount={pageCount}
      onPageChange={onPageChange}
      forcePage={forcePage}
      previousLabel="<"
      renderOnZeroPageCount={null}
      containerClassName="pagination-container"
      pageClassName="pagination-item"
      pageLinkClassName="pagination-link"
      previousClassName="pagination-item"
      previousLinkClassName="pagination-link"
      nextClassName="pagination-item"
      nextLinkClassName="pagination-link"
      breakClassName="pagination-item"
      breakLinkClassName="pagination-link"
      activeClassName="pagination-active"
    />
  );
};

export default Pagination;
