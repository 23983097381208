import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getReviews, toggleReviewStatus } from "../api/dashboardAPI";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import { Rating } from "react-simple-star-rating";
const Reviews = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [dirty, setDirty] = useState(true);

  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    getReviews(token, page + 1).then((res) => {
      setReviews(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
      setDirty(false);
    });
  }, [page, dirty]);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <div>
        <h1 className="title">Recensioni</h1>
        <ul>
          {reviews.length > 0 &&
            reviews.map((review) => {
              return (
                <li className="review-card" key={review.id}>
                  <div className="review">
                    <Rating
                      initialValue={review.rating}
                      SVGstyle={{ width: "20px" }}
                      readonly
                    />
                    <div className="review-card-title">{review.title}</div>
                    <div className="review-card-description">
                      {review.description}
                    </div>
                  </div>
                  <div className="review-card-id">
                    Id Utente: {review.user_id}
                  </div>
                  <div className="review-card-id">
                    Id Rider: {review.reviewable_id}
                  </div>
                  <div className="review-card-buttons">
                    <button
                      style={{ marginTop: "2rem" }}
                      onClick={() =>
                        toggleReviewStatus(token, {
                          id: review.id,
                          action: "Approve",
                        }).then(() => {
                          setLoading(true);
                          setDirty(true);
                        })
                      }
                      className="btn"
                    >
                      Approva
                    </button>
                    <button
                      style={{ marginTop: "2rem" }}
                      onClick={() =>
                        toggleReviewStatus(token, {
                          id: review.id,
                          action: "Hide",
                        }).then(() => setDirty(true))
                      }
                      className="btn"
                    >
                      Rifiuta
                    </button>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </>
  );
};

export default Reviews;
