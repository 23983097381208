import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getReport, getUser, processReport } from "../api/dashboardAPI";
import Loader from "../components/Loader";
import dayjs from "dayjs";

const SingleReport = (props) => {
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState();
  const [reporter, setReporter] = useState({});
  const [reportable, setReportable] = useState({});
  const navigate = useNavigate();
  const { idReport } = useParams();

  useEffect(() => {
    getReport(token, idReport).then((res) => {
      setReport(res.data);
      getUser(token, res.data.reporter_id).then((res) => setReporter(res.data));
      getUser(token, res.data.reportable_entity_id).then((res) => {
        setReportable(res.data);
        setLoading(false);
      });
    });
  }, [idReport, token]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div>
        <h3 style={{ marginBottom: "2rem" }}>{reporter.email} </h3>

        <div style={{ marginBottom: "0.5rem", display: "flex" }}>
          <div style={{ fontWeight: "800" }}>Segnalato:&nbsp; </div>
          <div> {reportable.email}</div>
        </div>
        <div style={{ marginBottom: "0.5rem", display: "flex" }}>
          <div style={{ fontWeight: "800" }}>Modello:&nbsp; </div>
          <div> {report.reportable_act_type}</div>
        </div>
        <div style={{ marginBottom: "0.5rem", display: "flex" }}>
          <div style={{ fontWeight: "800" }}>Id Modello:&nbsp; </div>
          <div> {report.reportable_act_id}</div>
        </div>
        <div style={{ marginBottom: "0.5rem", display: "flex" }}>
          <div style={{ fontWeight: "800" }}>Data:&nbsp; </div>
          <div> {dayjs(report.created_at).format("DD/MM/YYYY HH:mm:ss")}</div>
        </div>
        <div style={{ marginBottom: "2rem", display: "flex" }}>
          <div style={{ fontWeight: "800" }}>Tipo segnalazione:&nbsp; </div>
          <div> {report.reason}</div>
        </div>

        <div style={{ marginBottom: "1rem", fontWeight: "800" }}>
          Descrizione:{" "}
        </div>
        <div className="report-card">
          <div className="review">
            <div className="report-card-description">
              {report.reason_comment}
            </div>
          </div>
          <div className="report-card-buttons"></div>
        </div>
        {!report.reviewed_at ? (
          <button
            className="btn"
            style={{ marginTop: "2rem" }}
            onClick={() =>
              processReport(token, {
                id: idReport,
                reviewer_note: "prova",
              }).then(navigate(-1))
            }
          >
            Chiudi
          </button>
        ) : null}
      </div>
    </>
  );
};
export default SingleReport;
