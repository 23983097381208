import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRideTiers } from "../api/dashboardAPI";
import Pagination from "../components/Pagination";
import { Link } from "react-router-dom";

const Configuration = () => {
  const [rideTiers, setRideTiers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const { token } = useSelector((state) => state.user);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };
  useEffect(() => {
    getRideTiers(token).then((res) => setRideTiers(res.data));

  }, [token]);
  return (
    <>
      <div className="">
      <h1 className="title">Configurazioni</h1>

        {rideTiers.map((tier) => (
          <div key={tier.id} className="list-item">
            <span>{tier.name}</span>
            <span>
              <Link to={"/configuration/" + tier.id}>
                <button className="btn">Dettagli</button>
              </Link>
            </span>
          </div>
        ))}
      </div>
      {/* <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      /> */}
    </>
  );
};

export default Configuration;
