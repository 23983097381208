import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTeam, toggleLocationActivation } from "../api/dashboardAPI";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { Formik, Form, Field } from "formik";
const Locations = () => {
  const [locations, setLocations] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [team, setTeam] = useState({});
  const [idMatematici, setidMatematici] = useState("");
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    if (token && dirty === true) {
      getTeam(token, id).then((res) => {
        setTeam(res.data);
        setLocations(res.data.locations);
        setLoading(false);
        setDirty(false);
      });
    }
  }, [dirty, token]);
  const handleSubmission = (externalId, locationId) => {
    toggleLocationActivation(token, {
      id: locationId,
      active: true,
      external_location_id: externalId,
    }).then(setDirty(true));
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="team">
        <p className="name">{team.name}</p>
        <p className="description">{team.description}</p>
      </div>
      <div>
        <h1 className="item-title">Location</h1>
        {locations?.length == 0 ? (
          <p style={{ marginTop: "2rem" }}>Nessuna location</p>
        ) : (
          locations?.map((location) => {
            return (
              <div className="item" key={location.id}>
                <div className="item-description">
                  <h4 className="item-name">{location.name}</h4>
                  <h5 className="item-info">{location.description}</h5>
                  <h5 className="item-info">{location.address}</h5>

                  {!location.active && (
                    <>
                      <Formik
                        initialValues={{
                          id: "",
                        }}
                        onSubmit={(values) =>
                          handleSubmission(values.id, location.id)
                        }
                      >
                        <Form className="event-form">
                          <label className="item-info">
                            Id Matematici: &nbsp;
                          </label>
                          <Field type="text" id="id" name="id"></Field>
                          <button type="submit" className="btn">
                            Abilita
                          </button>
                        </Form>
                      </Formik>
                    </>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default Locations;
