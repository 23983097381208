import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createTag, getTags, removeTag } from "../api/dashboardAPI";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import { X } from "react-bootstrap-icons";
const Tags = (props) => {
  const { token } = useSelector((state) => state.user);
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState("");
  const [type, setType] = useState("Event");
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [dirty, setDirty] = useState(true);

  useEffect(() => {
    setLoading(true);

    getTags(token, page + 1).then((res) => {
      setTags(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
      setDirty(false);
    });
  }, [dirty, page]);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };

  const handleClick = () => {
    const newTag = { name: tagName, taggable_type: type };
    createTag(token, newTag).then(() => setDirty(true));
  };

  return (
    <div className="tagBigContainer">
      <div className="title">Crea tag</div>
      <div className="form-container">
        <form className="tag-form">
          <div className="tag-name">
            <label>Inserisci nome tag</label>
            <input
              type={"text"}
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            ></input>
          </div>
          <div className="tag-category">
            <label> Categoria Tag</label>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              <option value={"Event"}>Evento</option>
            </select>
          </div>
        </form>
        <button className="btn" onClick={handleClick}>
          Crea
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="tags-container">
            {tags?.map((tag) => {
              return (
                <div className="tag" key={tag.id}>
                  <p>{tag.name}</p>
                  <button
                    className="remove-btn"
                    onClick={() =>
                      removeTag(token, tag.id).then(() =>
                        setTags((prev) => {
                          return prev.filter((el) => el.id != tag.id);
                        })
                      )
                    }
                  >
                    <X />
                  </button>
                </div>
              );
            })}
          </div>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
            forcePage={page}
          />
        </>
      )}
    </div>
  );
};
export default Tags;
