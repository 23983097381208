import React, { useState, useEffect } from "react";
import { getProcessedReports, getUsers, getValidatorReports } from "../api/dashboardAPI";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import dayjs from "dayjs";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [processedReports, setProcessedReports] = useState([]);

  const { token } = useSelector((state) => state.user);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [processedPageCount, setProcessedPageCount] = useState(0);
  const [processedPage, setProcessedPage] = useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getValidatorReports(token, page + 1).then((res) => {
      setReports(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);

    });
    getProcessedReports(token,page + 1).then((res) => {
      setProcessedReports(res.data);
      setProcessedPageCount(res.meta.last_page);

    });
  }, [page, token]);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };
  const handleProcessedPageChange = (selectedObject) => {
    setProcessedPage(selectedObject.selected);
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <h1 className="title">Report</h1>
      <ul>
        {reports.map((report) => {
          return (
            <li className="list-item" key={report.id}>
              <div style={{ marginRight: "auto", whiteSpace: "normal" }}>
                {report.reason}
              </div>
              {/*  {user.role.map((r, index) => (
                <span key={index}>{r}</span>
              ))} */}
              <span
                className="list-item-button"
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                {dayjs(report.created_at).format("DD/MM/YYYY HH:mm:ss")}
                <Link to={"/report/" + report.id}>
                  <button className="btn">Dettagli</button>
                </Link>
              </span>
            </li>
          );
        })}
      </ul>
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />

      <h1 className="title">Archivio Report</h1>
      <ul>
        {processedReports.map((report) => {
          return (
            <li className="list-item" key={report.id}>
              <div style={{ marginRight: "auto", whiteSpace: "normal" }}>
                {report.reason}
              </div>
              {/*  {user.role.map((r, index) => (
                <span key={index}>{r}</span>
              ))} */}
              <span
                className="list-item-button"
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                {dayjs(report.created_at).format("DD/MM/YYYY HH:mm:ss")}
                <Link to={"/report/" + report.id}>
                  <button className="btn">Dettagli</button>
                </Link>
              </span>
            </li>
          );
        })}
      </ul>
      <Pagination
        pageCount={processedPageCount}
        onPageChange={handleProcessedPageChange}
        forcePage={processedPage}
      />
    </>
  );
};

export default Reports;
