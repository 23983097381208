import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "90%",
      }}
    >
      <ClipLoader color={"white"} />
    </div>
  );
};

export default Loader;
