import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getActivableRiders, getActiveRiders } from "../api/dashboardAPI";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
const Riders = () => {
  const [activableRiders, setActivableRiders] = useState([]);
  const [activeRiders, setActiveRiders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCountActive, setPageCountActive] = useState(0);
  const [pageActive, setPageActive] = useState(0);
  const [pageCountActivable, setPageCountActivable] = useState(0);
  const [pageActivable, setPageActivable] = useState(0);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    getActivableRiders(token, pageActivable + 1).then((res) => {
      setActivableRiders(res.data);
      setPageCountActivable(res.meta.last_page);
      setLoading(false);
    });
  }, [pageActivable]);

  useEffect(() => {
    getActiveRiders(token, pageActive + 1).then((res) => {
      setActiveRiders(res.data);
      setPageCountActive(res.meta.last_page);
      setLoading(false);
    });
  }, [pageActive]);

  const handlePageChangeActive = (selectedObject) => {
    setPageActive(selectedObject.selected);
  };
  const handlePageChangeActivable = (selectedObject) => {
    setPageActivable(selectedObject.selected);
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      {
        <>
          <h1 className="title" style={{ color: "white" }}>
            Riders in attesa di attivazione
          </h1>
          <ul>
            {activableRiders?.map((rider) => {
              return (
                <li className="list-item" key={rider.id}>
                  {rider.user.name}

                  <Link to={"/riders/" + rider.id}>
                    <button className="btn">Dettagli</button>
                  </Link>
                </li>
              );
            })}
          </ul>
          <Pagination
            pageCount={pageCountActivable}
            onPageChange={handlePageChangeActivable}
            forcePage={pageActivable}
          />
        </>
      }
      <h1 className="title" style={{ color: "white" }}>
        Riders attivi
      </h1>
      <ul>
        {activeRiders?.map((rider) => {
          return (
            <li className="list-item" key={rider.id}>
              {rider.user.name}

              <Link to={"/riders/" + rider.id}>
                <button className="btn">Dettagli</button>
              </Link>
            </li>
          );
        })}
      </ul>
      <Pagination
        pageCount={pageCountActive}
        onPageChange={handlePageChangeActive}
        forcePage={pageActive}
      />
    </div>
  );
};

export default Riders;
