import axios from "axios";

const BASEURL = process.env.REACT_APP_BASEURL;

const checkResponseStatus = (response) => {
  if (response.status > 399) {
    return response.text().then((text) => {
      throw new Error(text);
    });
  } else {
    return response.data;
  }
};

export async function fetchLogin(data) {
  try {
    const response = await axios.post(BASEURL + "/api/user/auth/login", data);
    return checkResponseStatus(response);
  } catch (e) {
    if (e) throw new Error(e);
  }
}
export async function fetchUserByToken(token) {
  try {
    const response = await axios.get(BASEURL + "/api/user/me", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return checkResponseStatus(response);
  } catch (e) {
    if (e) throw new Error(e);
  }
}

export async function getActiveTeams(token) {
  try {
    const response = await axios.get(
      BASEURL + "/api/backoffice/activation/get/Team/true",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return checkResponseStatus(response);
  } catch (e) {
    if (e) throw new Error(e);
  }
}
