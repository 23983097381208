import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getEvent,
  setEventStatus,
  getTables,
  setTickeTierInfo,
} from "../api/dashboardAPI";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Loader from "../components/Loader";
import { currencyFormatter } from "../utils/currencyFormatter";
import { Formik, Form, Field } from "formik";
const SingleEvent = () => {
  const [event, setEvent] = useState({});
  const [tables, setTables] = useState([]);
  const [tickets, setTickets] = useState([]);

  const { token } = useSelector((state) => state.user);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [externalId, setExternalId] = useState("");
  const [dirty, setDirty] = useState(true);
  const navigate = useNavigate();
  const handleClickApprove = (e) => {
    e.preventDefault();
    const body = {
      id,
      action: "Approve",
      external_event_id: externalId,
    };
    setEventStatus(token, body).then(setDirty(true));
  };
  const handleClickReject = (e) => {
    e.preventDefault();
    const body = {
      id,
      action: "Reject",
    };
    setEventStatus(token, body).then(() => navigate("/events"));
  };
  const handleClickPublish = (e) => {
    e.preventDefault();
    const body = {
      id,
      action: "Publish",
    };
    setEventStatus(token, body).then(() => navigate("/events"));
  };

  const handleClickTicket = (externalId, ticketId) => {
    const body = {
      id: ticketId,
      external_ticket_tier_id: externalId,
    };
    setTickeTierInfo(token, body).then(() => setDirty(true));
  };

  useEffect(() => {
    getEvent(token, id).then((res) => {
      setEvent(res.data.event);
      setTickets(res.data.ticket_tier);
      setLoading(false);
      setDirty(false);
    });
    getTables(token, id).then((res) => {
      setTables(res.data);
    });
  }, [dirty]);
  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="item-container">
        <h1 className="name">{event.name}</h1>
        <p className="item-description">{event.description}</p>
        <p className="item-info">
          {"Data Inizio: "}
          {dayjs(event.date_begin).format("DD/MM/YYYY HH:mm")}
        </p>
        <p className="item-info">
          {"Data Fine: "}
          {dayjs(event.date_end).format("DD/MM/YYYY HH:mm")}
        </p>
        <p className="item-info" style={{ marginTop: "2rem" }}>
          {"Stato: "}
          {event.state}
        </p>
        {event.state === "ApprovalRequested" && (
          <>
            <form className="event-form">
              <label className="item-info">Id Matematici: &nbsp;</label>
              <input
                type={"text"}
                value={externalId}
                onChange={(e) => setExternalId(e.target.value)}
              ></input>
            </form>
            <div style={{ display: "flex", gap: "2rem", marginTop: "2rem" }}>
              <button className="btn" onClick={handleClickApprove}>
                Accetta
              </button>
              <button className="btn" onClick={handleClickReject}>
                Rifiuta
              </button>
            </div>
          </>
        )}
        {event.state === "Approved" && (
          <div style={{ display: "flex", gap: "2rem", marginTop: "2rem" }}>
            <button className="btn" onClick={handleClickPublish}>
              Pubblica
            </button>
          </div>
        )}
      </div>
      <div>
        <h3 className="title-secondary">Modalità di ingresso</h3>
        {tickets.length == 0 ? (
          <p style={{ marginTop: "3rem" }}>Nessuna modalità di ingresso</p>
        ) : (
          tickets.map((ticket) => {
            return (
              <div className="item" key={ticket.id}>
                <p className="item-name">{ticket.name}</p>

                <p className="item-description">{ticket.description}</p>
                <p className="item-info">
                  {"N° Biglietti: "}
                  {ticket.quantity}
                </p>

                <p className="item-info">
                  {"Prezzo: "}
                  {currencyFormatter(ticket.price)}
                </p>
                {!ticket.external_ticket_tier_id && (
                  <>
                    <Formik
                      initialValues={{
                        id: "",
                      }}
                      onSubmit={(values) =>
                        handleClickTicket(values.id, ticket.id)
                      }
                    >
                      <Form className="event-form">
                        <label className="item-info">
                          Id Matematici: &nbsp;
                        </label>
                        <Field type="text" id="id" name="id"></Field>
                        <button
                          className="btn"
                          onClick={(e) => handleClickTicket(e, ticket.id)}
                        >
                          Accetta
                        </button>
                      </Form>
                    </Formik>
                  </>
                )}
              </div>
            );
          })
        )}
      </div>
      <div>
        <h3 className="title-secondary">Tavoli</h3>
        {tables.length == 0 ? (
          <p style={{ marginTop: "3rem" }}>Nessun tavolo</p>
        ) : (
          tables.map((table) => {
            return (
              <div className="item" key={table.id}>
                <p className="item-name">{table.name}</p>

                <p className="item-description">{table.description}</p>
                <p className="item-info">
                  {"N° Tavoli: "}
                  {table.quantity}
                </p>
                <p className="item-info">
                  {"Posti Massimi: "}
                  {table.max_party_size}
                </p>
                <p className="item-info">
                  {"Posti Minimi: "}
                  {table.min_party_size}
                </p>
                <p className="item-info">
                  {"Costo tavolo: "}
                  {currencyFormatter(table.price)}
                </p>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default SingleEvent;
