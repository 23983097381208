import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, getUserInfo } from "../store/user";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, userInfo, token } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    if (token && !userInfo) {
      dispatch(getUserInfo());
    }
    if (userInfo) {
      navigate("/subscriptions");
    }
  }, [token, userInfo]);

  const handleLogin = (e) => {
    e.preventDefault();
    const loginData = {
      email: username,
      password: password,
      device_name: "browser",
    };
    dispatch(login(loginData));
  };

  return (
    <>
      <div className="loginContainer">
        <div className="loginTitle"></div>
        <form onSubmit={handleLogin} className="loginForm">
          <label>Email</label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label>Password</label>
          <input
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <span style={{ color: "red" }}>
              Ops, qualcosa è andato storto! (Verifica email e password e
              riprova).
            </span>
          )}
          <div className="loginButtonContainer">
            <button className="btn" disabled={loading}>
              Accedi
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
