import React, { useState, useEffect } from "react";
import { getUsers } from "../api/dashboardAPI";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";

const Users = () => {
  const [users, setUsers] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getUsers(token, page + 1).then((res) => {
      setUsers(res.data);
      setPageCount(res.meta.last_page);
      setLoading(false);
    });
  }, [page, token]);

  const handlePageChange = (selectedObject) => {
    setPage(selectedObject.selected);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <h1 className="title">Utenti</h1>
      <ul>
        {users.map((user) => {
          return (
            <li className="list-item" key={user.id}>
              <div style={{ marginRight: "auto", whiteSpace: "normal" }}>
                {user.email}
              </div>
              {/*  {user.role.map((r, index) => (
                <span key={index}>{r}</span>
              ))} */}
              <span className="list-item-button">
                <Link to={"/roles/" + user.id}>
                  <button className="btn">Dettagli</button>
                </Link>
              </span>
            </li>
          );
        })}
      </ul>
      <Pagination
        pageCount={pageCount}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </>
  );
};

export default Users;
